import { useState } from 'react'
import api from '../../api'
import AmountInput from '../atoms/AmountInput'
import Button from '../atoms/Button'

const EndDayPopup = ({ setShow }) => {
  const [endAmount, setEndAmount] = useState(0)
  const [success, setSuccess] = useState(false)
  const [pdfURL, setPdfURL] = useState('')

  const endday = async () => {
    try {
      const data = await api({
        endpoint: '/cashstates/end',
        method: 'POST',
        body: { end_amount: endAmount },
      })

      console.log(data)

      setPdfURL(`/pdf/${data.pdf}`)
      setSuccess(true)
    } catch (err) {}
  }

  return (
    <div className="fixed inset-0 grid place-items-center bg-black bg-opacity-25">
      <div className="bg-gray-100 rounded-lg p-10 flex flex-col gap-2">
        {success ? (
          <>
            <div className="flex justify-center">
              <span className="uppercase text-lg font-semibold">
                Dag beëindigd
              </span>
            </div>
            <div className="flex justify-center mt-10">
              <a href={pdfURL} download>
                <Button>Download overzicht</Button>
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center">
              <span className="uppercase text-lg font-semibold">
                Dag beëindigen
              </span>
            </div>
            <div className="flex flex-col items-center gap-2 my-10">
              <span className="uppercase">Eind saldo kas</span>
              <AmountInput amount={endAmount} setAmount={setEndAmount} />
            </div>
            <div>
              <button
                onClick={endday}
                className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-green-500 hover:bg-green-600"
              >
                Dag beëindigen
              </button>
            </div>
            <div>
              <button
                onClick={() => setShow(false)}
                className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-red-600 hover:bg-red-700"
              >
                Annuleren
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default EndDayPopup
