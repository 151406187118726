import printJS from 'print-js'
import Logo from '../../assets/Logo'
import Button from '../atoms/Button'

const Receipt = ({
  receipt,
  setReceipt,
  contractNumber = '',
  contractType = '',
}) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 grid place-items-center">
      <div className="p-3 flex flex-col gap-3 bg-white">
        <div className="p-2 border border-neutral-500">
          <div id="receipt" className="text-xs">
            <div className="w-full grid place-items-center mt-5">
              <Logo className="w-[125px]" />
            </div>
            <div className="w-full mt-5 -mb-5 flex flex-col">
              <div className="flex items-center justify-between font-mono">
                <span>{contractType}</span>
                <span>{contractNumber}</span>
              </div>
            </div>
            <pre>{atob(receipt)}</pre>
          </div>
        </div>
        <div className="w-full flex justify-between">
          <button
            onClick={() => setReceipt('')}
            className="px-3 py-2 bg-red-600 text-white text-sm font-semibold rounded-lg shadow-sm"
          >
            Sluiten
          </button>
          <Button
            onClick={() =>
              printJS({
                printable: 'receipt',
                type: 'html',
                font_size: 12,
                targetStyles: ['*'],
              })
            }
          >
            Afdrukken
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Receipt
