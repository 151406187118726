import useCashstate from '../../contexts/CashstateContext'
import AmountInput from '../atoms/AmountInput'

const StartDayPopup = () => {
  const { startAmount, setStartAmount, startDay } = useCashstate()

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 grid place-items-center">
      <div className="bg-gray-100 rounded-lg p-10 flex flex-col gap-10">
        <div className="flex justify-center">
          <span className="uppercase text-lg font-semibold">Dagstart</span>
        </div>
        <div className="flex flex-col items-center gap-2">
          <span className="uppercase">Begin saldo kas</span>
          <AmountInput amount={startAmount} setAmount={setStartAmount} />
        </div>
        <div>
          <button
            onClick={startDay}
            className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-green-500 hover:bg-green-600"
          >
            Start dag
          </button>
        </div>
      </div>
    </div>
  )
}

export default StartDayPopup
