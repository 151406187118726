import useGeneral from '../../contexts/GeneralContext'

const TerminalSelect = () => {
  const { terminal, setTerminal, terminals } = useGeneral()

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-lg uppercase">PIN Terminal</h2>
      <div className="flex items-center gap-2">
        {terminals.map((item) => (
          <button
            key={item.id}
            onClick={() => setTerminal(item.id)}
            className={`w-80 h-20 flex flex-col items-center justify-center ${
              terminal === item.id ? 'bg-rixax-primary' : 'bg-gray-200'
            }`}
          >
            <span className="text-lg uppercase font-semibold">{item.name}</span>
            <span>{item.id}</span>
          </button>
        ))}
      </div>
    </div>
  )
}

export default TerminalSelect
