import { useEffect, useState } from 'react'
import api from '../api'
import Button from '../components/atoms/Button'
import Spinner from '../components/atoms/Spinner'
import EditUserPopup from '../components/templates/gebruikers/EditUserPopup'
import GebruikerToevoegenPopup from '../components/templates/gebruikers/GebruikerToevoegenPopup'
import PageLayout from '../layouts/PageLayout'

const Gebruikers = () => {
  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState([])

  const [addUser, setAddUser] = useState(false)
  const [editUser, setEditUser] = useState('')

  const getUsers = async () => {
    try {
      setLoading(true)
      const data = await api({
        endpoint: '/users',
      })

      setUsers(data.users)
      setLoading(false)
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getUsers()
  }, [addUser, editUser])

  const deactivateUser = async (id) => {
    try {
      await api({
        endpoint: `/users/${id}`,
        method: 'DELETE',
      })

      getUsers()
    } catch (err) {}
  }

  const activateUser = async (id) => {
    try {
      await api({
        endpoint: `/users/activate/${id}`,
        method: 'PUT',
      })

      getUsers()
    } catch (err) {}
  }

  if (loading)
    return (
      <PageLayout>
        <div className="w-full h-full grid place-items-center">
          <Spinner />
        </div>
      </PageLayout>
    )

  return (
    <PageLayout>
      <div className="w-full h-full flex flex-col gap-5">
        <div className="w-full flex items-center justify-between">
          <h2 className="uppercase">Gebruikers</h2>
          <Button onClick={() => setAddUser(true)}>Toevoegen</Button>
        </div>
        <ul className="w-full h-[70vh] flex flex-col gap-1 overflow-y-auto">
          <li className="w-full flex items-center gap-5 px-3 py-1 text-sm font-semibold">
            <span className="w-60 flex-shrink-0">ID</span>
            <span className="w-60 flex-shrink-0">Status</span>
            <span className="w-60 flex-shrink-0">Gebruikersnaam</span>
            <span className="w-60 flex-shrink-0">Rol</span>
            <span className="w-60 flex-shrink-0">Voornaam</span>
            <span className="w-60 flex-shrink-0">Achternaam</span>
          </li>
          {users.map((user) => (
            <li
              className="w-full flex items-center gap-5 px-3 py-1 bg-gray-100"
              key={user._id}
            >
              <span className="w-60 flex-shrink-0">{user._id}</span>
              <span className="w-60 flex-shrink-0">{user.status}</span>
              <span className="w-60 flex-shrink-0">{user.username}</span>
              <span className="w-60 flex-shrink-0">{user.role}</span>
              <span className="w-60 flex-shrink-0">{user.firstname}</span>
              <span className="w-60 flex-shrink-0">{user.lastname}</span>
              <div className="ml-auto flex items-center gap-5">
                <button onClick={() => setEditUser(user._id)}>
                  <i className="fa-solid fa-pen text-sm" />
                </button>
                {user.status === 'active' ? (
                  <button onClick={() => deactivateUser(user._id)}>
                    <i className="fa-solid fa-xmark text-lg text-red-600" />
                  </button>
                ) : (
                  <button onClick={() => activateUser(user._id)}>
                    <i className="fa-solid fa-check text-lg text-green-600" />
                  </button>
                )}
              </div>
            </li>
          ))}
        </ul>
      </div>
      {addUser && <GebruikerToevoegenPopup setShow={setAddUser} />}
      {editUser && (
        <EditUserPopup id={editUser} setShow={() => setEditUser('')} />
      )}
    </PageLayout>
  )
}

export default Gebruikers
