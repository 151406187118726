import { useEffect, useState } from 'react'
import api from '../api'
import Input from '../components/atoms/Input'
import Receipt from '../components/organisms/Receipt'
import TransactionFilters from '../components/templates/transactions/TransactionFilters'
import PageLayout from '../layouts/PageLayout'
import EURO from '../utils/euro'

const Transacties = () => {
  const [transactions, setTransactions] = useState([])

  const [search, setSearch] = useState('')
  const [status, setStatus] = useState(['completed'])
  const [type, setType] = useState(['CASH', 'PIN'])
  const [insphireType, setInsphireType] = useState(['CONTRACT', 'SALESORDER'])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)

  const [receipt, setReceipt] = useState('')
  const [contractNumber, setContractNumber] = useState('')
  const [contractType, setContractType] = useState('')

  const getTransactions = async () => {
    const q = search
      ? `?search=${search}&page=${page - 1}&per_page=12`
      : `?status=${status.join('&status=')}&transaction_type=${type.join(
          '&transaction_type='
        )}&insphire_type=${insphireType.join('&insphire_type=')}&page=${
          page - 1
        }&per_page=12`

    const data = await api({
      endpoint: `/transactions${q}`,
    })

    setTotalPages(data.query_pages)
    setTransactions(data.transactions)
  }

  useEffect(() => {
    let timeout = null
    if (search) {
      timeout = setTimeout(() => getTransactions(), 750)
    } else {
      getTransactions()
    }

    return () => {
      if (timeout) clearTimeout(timeout)
    }
  }, [search, status, type, insphireType, page])

  const showReceipt = (transaction) => {
    setReceipt(transaction.receipt || '')
    setContractNumber(transaction.insphire_number)
    setContractType(transaction.insphire_type)
  }

  return (
    <PageLayout>
      <div className="w-full flex flex-col gap-5">
        <div className="w-full flex items-center">
          <h2 className="uppercase text-xl">Transacties</h2>
        </div>
        <div>
          <Input
            type="search"
            name="search"
            label="Zoeken"
            value={search}
            setValue={setSearch}
          />
        </div>
        <TransactionFilters
          status={status}
          setStatus={setStatus}
          type={type}
          setType={setType}
          insphireType={insphireType}
          setInsphireType={setInsphireType}
        />
        <div className="w-full flex flex-col">
          <div className="w-full flex items-center gap-2 px-2 text-sm font-semibold">
            <span className="w-40 flex-shrink-0 truncate">Datum</span>
            <span className="w-24 flex-shrink-0 truncate">Status</span>
            <span className="w-20 flex-shrink-0 truncate">Type</span>
            <span className="w-32 flex-shrink-0 truncate">Insphire type</span>
            <span className="w-40 flex-shrink-0 truncate">Insphire nummer</span>
            <span className="w-40 flex-shrink-0 truncate ml-auto text-end">
              Bedrag
            </span>
            <div className="w-20 flex-shrink-0 truncate text-end"></div>
          </div>
          <div className="w-full flex flex-col h-[50vh] overflow-y-auto my-1">
            {transactions.map((trans) => (
              <div
                key={trans._id}
                className="w-full flex items-center gap-2 px-2 py-1 border-b"
              >
                <span className="w-40 flex-shrink-0 truncate">
                  {new Date(trans.created).toLocaleDateString()}{' '}
                  {new Date(trans.created).toLocaleTimeString()}
                </span>
                <span className="w-24 flex-shrink-0 truncate">
                  {trans.status}
                </span>
                <span className="w-20 flex-shrink-0 truncate">
                  {trans.transaction_type}
                </span>
                <span className="w-32 flex-shrink-0 truncate">
                  {trans.insphire_type}
                </span>
                <span className="w-40 flex-shrink-0 truncate">
                  {trans.insphire_number}
                </span>
                <span className="w-40 flex-shrink-0 truncate ml-auto text-end">
                  {EURO(trans.amount).format()}
                </span>
                <div className="w-20 flex-shrink-0 flex items-center justify-end gap-3">
                  {trans.receipt && (
                    <button
                      onClick={() => showReceipt(trans)}
                      className="text-neutral-900 hover:text-rixax-primary text-xl"
                    >
                      <i className="fa-solid fa-receipt" />
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center gap-10">
            {page !== 1 && (
              <button
                className="p-3"
                onClick={() => setPage((prev) => prev - 1)}
              >
                <i className="fa-solid fa-chevron-left" />
              </button>
            )}
            <span>
              {page} of {totalPages}
            </span>
            {page !== totalPages && (
              <button
                className="p-3"
                onClick={() => setPage((prev) => prev + 1)}
              >
                <i className="fa-solid fa-chevron-right" />
              </button>
            )}
          </div>
        </div>
      </div>
      {receipt && (
        <Receipt
          receipt={receipt}
          setReceipt={setReceipt}
          contractNumber={contractNumber}
          contractType={contractType}
        />
      )}
    </PageLayout>
  )
}

export default Transacties
