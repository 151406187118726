const api = async ({ endpoint, method = 'GET', headers = {}, body = null }) => {
  try {
    const res = await fetch(`/api/v1${endpoint}`, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      ...(body && { body: JSON.stringify(body) }),
    })
    const data = await res.json()

    return data
  } catch (err) {}
}

export default api
