import EURO from '../../../utils/euro'

const paymentMethods = {
  0: 'Cash',
  2: 'PIN',
}

const types = {
  1: 'Offerte',
  2: 'Borg',
  3: 'Betaling',
  4: 'Terugbetaling',
}

const ContractTransactions = ({ transactions }) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="uppercase">Transacties</h2>
      <div>
        <div className="w-full px-2 py-1 flex items-center gap-2 text-xs font-semibold">
          <span className="w-40 flex-shrink-0 truncate">Datum</span>
          <span className="w-32 flex-shrink-0 truncate">Methode</span>
          <span className="w-32 flex-shrink-0 truncate">Type</span>
          <span className="truncate ml-auto">Bedrag</span>
        </div>
        <div className="flex flex-col max-h-[20rem] overflow-y-auto">
          {transactions.map((item) => (
            <div
              key={item.RECID}
              className="w-full px-2 py-1 flex items-center gap-2 border-b"
            >
              <span className="w-40 flex-shrink-0 truncate">
                {new Date(item.DATE).toLocaleDateString()} {item.TIME}
              </span>
              <span className="w-32 flex-shrink-0 truncate uppercase">
                {paymentMethods[item.PAYMETH] || ''}
              </span>
              <span className="w-32 flex-shrink-0 truncate uppercase">
                {types[item.TYPE] || ''}
              </span>
              <span className="truncate ml-auto">
                {EURO(item.AMOUNT).format()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContractTransactions
