import Header from '../components/organisms/Header'

const PageLayout = ({ children }) => {
  return (
    <>
      <Header />
      <main className="w-full h-page p-5 overflow-x-hidden overflow-y-auto">
        {children}
      </main>
    </>
  )
}

export default PageLayout
