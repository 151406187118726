import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from 'recharts'
import EURO from '../../../utils/euro'

const RADIAN = Math.PI / 180
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="bg-neutral-900 text-white p-2 shadow-md flex items-center gap-2">
        <span className="font-semibold text-sm">{payload[0].name}</span>
        <span>{EURO(payload[0].value).format()}</span>
      </div>
    )
  }

  return null
}

const BetaalMethoden = ({ stats }) => {
  const methodsToday = [
    { name: 'PIN', value: stats ? stats.total_amount_pin_today : 0 },
    { name: 'Cash', value: stats ? stats.total_amount_cash_today : 0 },
  ]

  const methodsWeek = [
    { name: 'PIN', value: stats ? stats.total_amount_pin_week : 0 },
    { name: 'Cash', value: stats ? stats.total_amount_cash_week : 0 },
  ]

  return (
    <div className="w-full h-full flex flex-col gap-2 border p-2">
      <h3 className="uppercase text-xl">Betaal methoden</h3>
      <div className="relative w-full h-full flex flex-col items-center">
        <div className="relative w-full h-[40vh] border-b">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={methodsToday}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                <Cell fill={'#22c55e'} label />
                <Cell fill={'#ca8a04'} label />
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
          <div className="absolute top-1 right-2">
            <span className="text-sm uppercase font-semibold">Vandaag</span>
          </div>
        </div>
        <div className="relative w-full h-[40vh]">
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={400} height={400}>
              <Pie
                data={methodsWeek}
                labelLine={false}
                label={renderCustomizedLabel}
                outerRadius={100}
                fill="#8884d8"
                dataKey="value"
              >
                <Cell fill={'#22c55e'} label />
                <Cell fill={'#ca8a04'} label />
              </Pie>
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
          <div className="absolute top-1 right-2">
            <span className="text-sm uppercase font-semibold">Week</span>
          </div>
        </div>
        <div className="absolute top-2 left-2 flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 bg-yellow-600" />
            <span>Cash</span>
          </div>
          <div className="flex items-center gap-2">
            <div className="w-5 h-5 bg-green-600" />
            <span>PIN</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BetaalMethoden
