import { useEffect, useState } from 'react'
import api from '../../../api'
import useCashstate from '../../../contexts/CashstateContext'
import EURO from '../../../utils/euro'
import Spinner from '../../atoms/Spinner'

const KassaInfo = () => {
  const { cashstate } = useCashstate()

  const [currentAmount, setCurrentAmount] = useState(0)

  const [cashAmount, setCashAmount] = useState(0)
  const [expenseAmount, setExpenseAmount] = useState(0)
  const [cashtransactionAmount, setCashtransactionAmount] = useState(0)

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getInfo = async () => {
      setLoading(true)

      const data = await api({
        endpoint: '/dashboard',
      })

      const expensesData = await api({
        endpoint: '/expenses/today',
      })

      const cashtransactionsData = await api({
        endpoint: '/cashtransactions/today',
      })

      setCashAmount(EURO(data.total_amount_cash_today).value)
      setExpenseAmount(EURO(expensesData.total_amount).value)
      setCashtransactionAmount(EURO(cashtransactionsData.total_amount).value)

      setCurrentAmount(
        EURO(cashstate.start_amount)
          .add(data.total_amount_cash_today)
          .subtract(expensesData.total_amount)
          .subtract(cashtransactionsData.total_amount).value
      )
      setLoading(false)
    }

    getInfo()
  }, [])

  if (loading)
    return (
      <div className="w-full grid place-items-center border">
        <Spinner />
      </div>
    )

  return (
    <div className="w-full flex flex-col gap-2 p-5 border">
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Datum</span>
        <span className="text-lg">{new Date().toLocaleDateString()}</span>
      </div>
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Begin saldo kas</span>
        <span className="text-lg">
          {EURO(cashstate ? cashstate.start_amount : 0).format()}
        </span>
      </div>
      <div className="w-full h-px bg-gray-200 my-1" />
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Totaal CASH</span>
        <span className="text-lg">{EURO(cashAmount).format()}</span>
      </div>
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Totaal uitgaven</span>
        <span className="text-lg">{`- ${EURO(expenseAmount).format()}`}</span>
      </div>
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Totaal kas transacties</span>
        <span className="text-lg">
          {`- ${EURO(cashtransactionAmount).format()}`}
        </span>
      </div>
      <div className="w-full h-px bg-gray-500 my-1" />
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Huidig saldo kas</span>
        <span className="text-lg">{EURO(currentAmount).format()}</span>
      </div>
    </div>
  )
}

export default KassaInfo
