import { useState } from 'react'
import EndDayPopup from '../components/organisms/EndDayPopup'
import KassaInfo from '../components/templates/kassa/KassaInfo'
import KassaPayments from '../components/templates/kassa/KassaPayments'
import KasTransacties from '../components/templates/kassa/KasTransacties'
import Uitgaven from '../components/templates/kassa/Uitgaven'
import PageLayout from '../layouts/PageLayout'

const Kassa = () => {
  const [showEndDayPopup, setShowEndDayPopup] = useState(false)

  return (
    <PageLayout>
      <div className="w-full h-full grid grid-cols-3 gap-10">
        <KassaInfo />
        <Uitgaven />
        <KasTransacties />
        <KassaPayments />
        <div
          onClick={() => setShowEndDayPopup(true)}
          className="w-full flex justify-end mt-auto col-span-2"
        >
          <button className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-red-600 hover:bg-red-700">
            Dag beëindigen
          </button>
        </div>
      </div>
      {showEndDayPopup && <EndDayPopup setShow={setShowEndDayPopup} />}
    </PageLayout>
  )
}

export default Kassa
