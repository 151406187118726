import { useEffect, useState } from 'react'
import api from '../api'
import Spinner from '../components/atoms/Spinner'
import PageLayout from '../layouts/PageLayout'
import Totalen from '../components/templates/dashboard/Totalen'
import BetaalMethoden from '../components/templates/dashboard/BetaalMethoden'

const Dashboard = () => {
  const [stats, setStats] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getStats = async () => {
      setLoading(true)
      const data = await api({ endpoint: '/dashboard?include=transactions' })

      setStats(data)
      setLoading(false)
    }

    getStats()
  }, [])

  if (loading)
    return (
      <PageLayout>
        <div className="w-full h-full grid place-items-center">
          <Spinner />
        </div>
      </PageLayout>
    )

  return (
    <PageLayout>
      <div className="w-full h-full grid grid-cols-2 gap-2 auto-rows-fr">
        <Totalen stats={stats} />
        <BetaalMethoden stats={stats} />
      </div>
    </PageLayout>
  )
}

export default Dashboard
