import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../api'
import Spinner from '../components/atoms/Spinner'
import CashAmountPopup from '../components/templates/contracts/CashAmountPopup'
import ContractAmount from '../components/templates/contracts/ContractAmount'
import ContractInfo from '../components/templates/contracts/ContractInfo'
import ContractItems from '../components/templates/contracts/ContractItems'
import ContractTransactions from '../components/templates/contracts/ContractTransactions'
import PinAmountPopup from '../components/templates/contracts/PinAmountPopup'
import useTransaction from '../contexts/TransactionContext'
import PageLayout from '../layouts/PageLayout'
import EURO from '../utils/euro'

const HuurcontractSingle = () => {
  const { contractnumber } = useParams()
  const { setAmount, startTransaction } = useTransaction()

  const [loading, setLoading] = useState(true)

  const [contract, setContract] = useState(null)
  const [prices, setPrices] = useState(null)
  const [items, setItems] = useState([])
  const [transactions, setTransactions] = useState([])

  const [cashPopup, setCashPopup] = useState(false)
  const [pinPopup, setPinPopup] = useState(false)

  useEffect(() => {
    const getContractInfo = async () => {
      setLoading(true)

      const data = await api({
        endpoint: `/contracts/price/${contractnumber}`,
      })

      const transactionData = await api({
        endpoint: `/contracts/transactions/${contractnumber}`,
      })

      setContract(data.contract)
      setPrices(data.price)
      setItems(data.items)
      setTransactions(transactionData.transactions)
      setLoading(false)
    }

    getContractInfo()
  }, [contractnumber])

  if (loading)
    return (
      <PageLayout>
        <div className="w-full h-full grid place-items-center">
          <Spinner />
        </div>
      </PageLayout>
    )

  const cashTransaction = () => {
    setAmount(
      EURO(prices.TOTAL).add(contract.ACCT === '000001' ? prices.DEPOSIT : 0)
        .value
    )
    setCashPopup(true)
  }

  const pinTransaction = () => {
    setAmount(
      EURO(prices.TOTAL).add(contract.ACCT === '000001' ? prices.DEPOSIT : 0)
        .value
    )
    setPinPopup(true)
  }

  const startCashTransaction = () => {
    setCashPopup(false)
    startTransaction({
      type: 'CASH',
      insphireType: 'CONTRACT',
      insphireNumber: contract.CONTNO,
    })
  }

  const startPinTransaction = () => {
    setPinPopup(false)
    startTransaction({
      type: 'PIN',
      insphireType: 'CONTRACT',
      insphireNumber: contract.CONTNO,
    })
  }

  return (
    <PageLayout>
      <div className="w-full h-full grid grid-cols-2 gap-10">
        <ContractInfo contract={contract} />
        <ContractItems contract={contract} items={items} />
        <ContractTransactions transactions={transactions} />
        <ContractAmount
          contract={contract}
          prices={prices}
          cashTransaction={cashTransaction}
          pinTransaction={pinTransaction}
        />
      </div>
      {cashPopup && (
        <CashAmountPopup
          setShow={setCashPopup}
          startTransaction={startCashTransaction}
        />
      )}
      {pinPopup && (
        <PinAmountPopup
          setShow={setPinPopup}
          startTransaction={startPinTransaction}
        />
      )}
    </PageLayout>
  )
}

export default HuurcontractSingle
