import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/index.css'
import './styles/theme.css'
import Routing from './Routing'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { BrowserRouter } from 'react-router-dom'
import { SessionProvider } from './contexts/SessionContext'
import { GeneralProvider } from './contexts/GeneralContext'
import { TransactionProvider } from './contexts/TransactionContext'
import { CashstateProvider } from './contexts/CashstateContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <div className="theme-rixax">
    <BrowserRouter>
      <SessionProvider>
        <CashstateProvider>
          <GeneralProvider>
            <TransactionProvider>
              <Routing />
            </TransactionProvider>
          </GeneralProvider>
        </CashstateProvider>
      </SessionProvider>
    </BrowserRouter>
  </div>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
