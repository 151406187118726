import { createContext, useContext, useEffect, useState } from 'react'
import api from '../api'
import Spinner from '../components/atoms/Spinner'

const SessionContext = createContext(null)

export const SessionProvider = ({ children }) => {
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState(false)
  const [loggedIn, setLoggedIn] = useState(false)
  const [user, setUser] = useState(null)

  useEffect(() => {
    const getSession = async () => {
      try {
        setLoading(true)
        const data = await api({ endpoint: '/sessions/me' })

        setSession(!!data.session)
        setLoggedIn(data.session.logged_in)
        setUser(data.session.user)
        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }

    getSession()
  }, [])

  const logout = async () => {
    const data = await api({ endpoint: '/logout', method: 'PUT' })

    if (data.success) {
      setLoggedIn(false)
      setUser(null)
      setSession(false)
    }
  }

  const values = {
    session,
    setSession,
    loggedIn,
    setLoggedIn,
    user,
    setUser,
    logout,
  }

  if (loading)
    return (
      <div className="w-full h-screen grid place-items-center">
        <Spinner />
      </div>
    )

  return (
    <SessionContext.Provider value={values}>{children}</SessionContext.Provider>
  )
}

const useSession = () => useContext(SessionContext)

export default useSession
