import TerminalSelect from '../components/organisms/TerminalSelect'
import TestmodeSelect from '../components/organisms/TestmodeSelect'
import useSession from '../contexts/SessionContext'
import PageLayout from '../layouts/PageLayout'

const Instellingen = () => {
  const { user } = useSession()

  return (
    <PageLayout>
      <div className="flex flex-col gap-5">
        <TerminalSelect />
        {user.role === 'admin' && <TestmodeSelect />}
      </div>
    </PageLayout>
  )
}

export default Instellingen
