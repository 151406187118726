import useGeneral from '../../contexts/GeneralContext'
import useTransaction from '../../contexts/TransactionContext'
import EURO from '../../utils/euro'
import AmountInput from '../atoms/AmountInput'
import Spinner from '../atoms/Spinner'

const Transaction = () => {
  const {
    status,
    statusText,
    type,
    amount,
    insphireNumber,
    insphireType,
    PINterminalName,
    PINtransactionID,
    PINpaymentReference,
    CASHamountPayed,
    setCASHamountPayed,
    CASHamountChange,
    cancelTransaction,
    completeTransaction,
  } = useTransaction()
  const { terminal } = useGeneral()

  return (
    <div className="fixed inset-0 z-50 grid place-items-center bg-black bg-opacity-25">
      <div className="p-5 bg-gray-100 rounded-lg flex flex-col gap-5">
        <div className="w-full flex justify-center">
          <h2 className="text-xl uppercase font-semibold">Transactie</h2>
        </div>
        <div className="flex gap-3">
          <div className="w-[30vw] h-[75vh] flex flex-col gap-1 px-10 py-5">
            <div className="flex items-center justify-between">
              <span className="font-semibold uppercase">Type transactie</span>
              <span className="text-lg uppercase">{type}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="font-semibold uppercase">Bedrag</span>
              <span className="text-2xl font-semibold truncate">
                {EURO(amount).format()}
              </span>
            </div>
            <div className="w-full h-px bg-neutral-300 my-2" />
            <div className="flex items-center justify-between">
              <span className="font-semibold uppercase">Insphire type</span>
              <span className="text-lg truncate">{insphireType}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="font-semibold uppercase">Insphire nummer</span>
              <span className="text-lg truncate">{insphireNumber}</span>
            </div>
            <div className="w-full h-px bg-neutral-300 my-2" />

            {type === 'PIN' && (
              <>
                <div className="flex items-center justify-between">
                  <span className="font-semibold uppercase">PIN Terminal</span>
                  <span className="text-lg truncate">{PINterminalName}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="font-semibold uppercase">
                    PIN Terminal ID
                  </span>
                  <span className="text-lg truncate">{terminal}</span>
                </div>
                <div className="w-full h-px bg-neutral-300 my-2" />
                <div className="flex items-center justify-between">
                  <span className="font-semibold uppercase">
                    Transactie ID (Pay.nl)
                  </span>
                  <span className="text-lg truncate">{PINtransactionID}</span>
                </div>
                <div className="flex items-center justify-between">
                  <span className="font-semibold uppercase">
                    Payment reference (Pay.nl)
                  </span>
                  <span className="text-lg truncate">
                    {PINpaymentReference}
                  </span>
                </div>
              </>
            )}

            {type === 'CASH' && <></>}
          </div>
          <div className="w-px h-[75vh] bg-neutral-300"></div>
          <div className="w-[30vw] h-[75vh] flex flex-col gap-20 px-10 py-5">
            {type === 'CASH' && (
              <>
                {status === 'pending' ? (
                  <>
                    <div className="flex flex-col gap-1">
                      <span className="uppercase text-center">Ontvangen</span>
                      <AmountInput
                        amount={CASHamountPayed}
                        setAmount={setCASHamountPayed}
                      />
                    </div>
                    <div className="flex flex-col gap-1">
                      <span className="uppercase text-center">Wisselgeld</span>
                      <span className="uppercase text-center text-3xl font-semibold">
                        {EURO(CASHamountChange).format()}
                      </span>
                    </div>
                    <div className="flex flex-col items-center gap-2 mt-auto">
                      <button
                        onClick={
                          CASHamountPayed >= amount
                            ? completeTransaction
                            : () => console.log('UNAVAILABLE')
                        }
                        className={`w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-green-500 hover:bg-green-600 ${
                          CASHamountPayed >= amount
                            ? 'opacity-100'
                            : 'opacity-50 cursor-not-allowed'
                        }`}
                      >
                        Voltooien
                      </button>
                      <button
                        onClick={cancelTransaction}
                        className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-red-600 hover:bg-red-700"
                      >
                        Annuleren
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="my-auto text-2xl uppercase font-semibold text-center">
                      {statusText}
                    </div>
                  </>
                )}
              </>
            )}

            {type === 'PIN' && (
              <>
                <div className="w-full h-1/4 flex flex-col justify-center">
                  <div className="flex flex-col gap-1">
                    <span className="uppercase text-center">{status}</span>
                  </div>
                  <div className="flex flex-col gap-1">
                    <span className="uppercase text-center">{statusText}</span>
                  </div>
                </div>
                <div className="w-full h-2/4 grid place-items-center">
                  {status === 'pending' && (
                    <>
                      <Spinner size="w-32" />
                    </>
                  )}

                  {status === 'error' && (
                    <>
                      <i className="fa-solid fa-circle-exclamation text-red-600 text-9xl" />
                    </>
                  )}

                  {status === 'cancelled' && (
                    <>
                      <i className="fa-solid fa-ban text-red-600 text-9xl" />
                    </>
                  )}

                  {status === 'completed' && (
                    <>
                      <i className="fa-solid fa-circle-check text-green-600 text-9xl" />
                    </>
                  )}
                </div>
                <div className="w-full h-2/4 grid place-items-center">
                  {status === 'pending' && (
                    <button
                      onClick={cancelTransaction}
                      className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-red-600 hover:bg-red-700"
                    >
                      Annuleren
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transaction
