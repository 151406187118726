const ContractInfo = ({ contract }) => {
  return (
    <div className="flex flex-col gap-2">
      <h2 className="uppercase">Contract</h2>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Contractnummer</span>
          <span className="">{contract.CONTNO}</span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Soort klant</span>
          <span className="">
            {contract.ACCT === '000001' ? 'Particulier' : 'Zakelijk'}
          </span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Klant</span>
          <span className="">{contract.ACCTNAME}</span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Klantnummer</span>
          <span className="">{contract.ACCT}</span>
        </div>
        {contract.ACCT === '000001' && (
          <div className="flex items-center gap-2 border-b justify-between">
            <span className="text-sm font-semibold">Contant klantnummer</span>
            <span className="">{contract.CCREC}</span>
          </div>
        )}
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Huurdatum</span>
          <span className="">
            {new Date(contract.HIREDATE).toLocaleDateString()}{' '}
            {contract.HIRETIME}
          </span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Verwachte retourdatum</span>
          <span className="">
            {new Date(contract.ESTRETD).toLocaleDateString()} {contract.ESTRETT}
          </span>
        </div>
        <div className="flex flex-col gap-2 border-b mt-3">
          <span className="text-sm font-semibold">Klant referentie</span>
          <span>{contract.THEIRREF}</span>
        </div>
        <div className="flex flex-col gap-2 border-b mt-3">
          <span className="text-sm font-semibold">Memo</span>
          <span>{contract.MEMO}</span>
        </div>
      </div>
    </div>
  )
}

export default ContractInfo
