const Button = ({ children, ...rest }) => {
  return (
    <button
      className="px-3 py-2 bg-rixax-primary text-sm font-semibold rounded-lg shadow-sm"
      {...rest}
    >
      {children}
    </button>
  )
}

export default Button
