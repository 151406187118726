import EURO from '../../../utils/euro'

const ContractItems = ({ contract, items }) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="uppercase">Items</h2>
      <div>
        <div className="w-full px-2 py-1 flex items-center gap-2 text-xs font-semibold">
          <span className="w-14 flex-shrink-0 truncate">Aantal</span>
          <span className="w-28 flex-shrink-0 truncate">Nummer</span>
          <span className="w-32 flex-shrink-0 truncate">Subgroep</span>
          <span className="w-full truncate">Omschrijving</span>
          <span className="w-24 flex-shrink-0 truncate text-end">Bedrag</span>
          <span className="w-24 flex-shrink-0 truncate text-end">Borg</span>
        </div>
        <div className="flex flex-col max-h-[20rem] overflow-y-auto">
          {items.map((item) => (
            <div
              key={item.RECID}
              className="w-full px-2 py-1 flex items-center gap-2 border-b"
            >
              <span className="w-14 flex-shrink-0 truncate">{item.QTY}</span>
              <span className="w-28 flex-shrink-0 truncate">{item.ITEMNO}</span>
              <span className="w-32 flex-shrink-0 truncate">{item.SUBGRP}</span>
              <span className="w-full truncate">{item.ITEMDESC}</span>
              <span className="w-24 flex-shrink-0 truncate text-end">
                {EURO(item.LINETOT).format()}
              </span>
              <span className="w-24 flex-shrink-0 truncate text-end">
                {EURO(contract.ACCT === '000001' ? item.DEPOSIT : 0)
                  .multiply(item.QTY)
                  .format()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default ContractItems
