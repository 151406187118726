const TransactionFilters = ({
  status,
  setStatus,
  type,
  setType,
  insphireType,
  setInsphireType,
}) => {
  return (
    <div className="w-full flex gap-20">
      <div className="flex flex-col gap-1">
        <label className="font-semibold">Status</label>
        <div className="flex items-center gap-2 mt-1">
          <input
            id="completed"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={status.includes('completed')}
            onChange={(e) =>
              e.target.checked
                ? setStatus((prev) => [...prev, 'completed'])
                : setStatus((prev) => prev.filter((a) => a !== 'completed'))
            }
          />
          <label className="cursor-pointer " htmlFor="completed">
            Completed
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            id="cancelled"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={status.includes('cancelled')}
            onChange={(e) =>
              e.target.checked
                ? setStatus((prev) => [...prev, 'cancelled'])
                : setStatus((prev) => prev.filter((a) => a !== 'cancelled'))
            }
          />
          <label className="cursor-pointer " htmlFor="cancelled">
            Cancelled
          </label>
        </div>
        <div className="flex items-center gap-2">
          <input
            id="pending"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={status.includes('pending')}
            onChange={(e) =>
              e.target.checked
                ? setStatus((prev) => [...prev, 'pending'])
                : setStatus((prev) => prev.filter((a) => a !== 'pending'))
            }
          />
          <label className="cursor-pointer " htmlFor="pending">
            Pending
          </label>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <label className="font-semibold">Type</label>
        <div className="flex items-center gap-2 mt-1">
          <input
            id="pin"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={type.includes('PIN')}
            onChange={(e) =>
              e.target.checked
                ? setType((prev) => [...prev, 'PIN'])
                : setType((prev) => prev.filter((a) => a !== 'PIN'))
            }
          />
          <label className="cursor-pointer" htmlFor="pin">
            PIN
          </label>
        </div>
        <div className="flex items-center gap-2 mt-1">
          <input
            id="cash"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={type.includes('CASH')}
            onChange={(e) =>
              e.target.checked
                ? setType((prev) => [...prev, 'CASH'])
                : setType((prev) => prev.filter((a) => a !== 'CASH'))
            }
          />
          <label className="cursor-pointer" htmlFor="cash">
            CASH
          </label>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <label className="font-semibold">Insphire type</label>
        <div className="flex items-center gap-2 mt-1">
          <input
            id="contract"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={insphireType.includes('CONTRACT')}
            onChange={(e) =>
              e.target.checked
                ? setInsphireType((prev) => [...prev, 'CONTRACT'])
                : setInsphireType((prev) =>
                    prev.filter((a) => a !== 'CONTRACT')
                  )
            }
          />
          <label className="cursor-pointer" htmlFor="contract">
            CONTRACT
          </label>
        </div>
        <div className="flex items-center gap-2 mt-1">
          <input
            id="verkooporder"
            type="checkbox"
            className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
            checked={insphireType.includes('SALESORDER')}
            onChange={(e) =>
              e.target.checked
                ? setInsphireType((prev) => [...prev, 'SALESORDER'])
                : setInsphireType((prev) =>
                    prev.filter((a) => a !== 'SALESORDER')
                  )
            }
          />
          <label className="cursor-pointer" htmlFor="verkooporder">
            SALESORDER
          </label>
        </div>
      </div>
    </div>
  )
}

export default TransactionFilters
