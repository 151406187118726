const DayEndedPopup = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 grid place-items-center">
      <div className="bg-gray-100 rounded-lg p-10 flex flex-col gap-10 w-96">
        <div className="flex justify-center">
          <i className="fa-solid fa-circle-exclamation text-red-600 text-6xl" />
        </div>
        <div className="flex justify-center">
          <span className="text-red-600 uppercase font-semibold">
            Dag is al afgesloten
          </span>
        </div>
      </div>
    </div>
  )
}

export default DayEndedPopup
