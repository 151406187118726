import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import api from '../api'
import Spinner from '../components/atoms/Spinner'
import CashAmountPopup from '../components/templates/contracts/CashAmountPopup'
import PinAmountPopup from '../components/templates/contracts/PinAmountPopup'
import SalesorderAmount from '../components/templates/salesorders/SalesorderAmount'
import SalesorderInfo from '../components/templates/salesorders/SalesorderInfo'
import SalesorderItems from '../components/templates/salesorders/SalesorderItems'
import SalesorderTransactions from '../components/templates/salesorders/SalesorderTransactions'
import useTransaction from '../contexts/TransactionContext'
import PageLayout from '../layouts/PageLayout'
import EURO from '../utils/euro'

const SalesOrderSingle = () => {
  const { salesordernumber } = useParams()
  const { setAmount, startTransaction } = useTransaction()

  const [loading, setLoading] = useState(true)

  const [salesorder, setSalesorder] = useState(null)
  const [items, setItems] = useState([])
  const [transactions, setTransactions] = useState([])

  const [cashPopup, setCashPopup] = useState(false)
  const [pinPopup, setPinPopup] = useState(false)

  useEffect(() => {
    const getSalesorderInfo = async () => {
      setLoading(true)

      const salesorderData = await api({
        endpoint: `/salesorders/${salesordernumber}`,
      })

      const itemsData = await api({
        endpoint: `/salesorders/items/${salesordernumber}`,
      })

      const transactionsData = await api({
        endpoint: `/salesorders/transactions/${salesordernumber}`,
      })

      console.log(salesorderData)
      console.log(itemsData)
      console.log(transactionsData)

      setSalesorder(salesorderData.salesorder)
      setItems(itemsData.items)
      setTransactions(transactionsData.transactions)

      setLoading(false)
    }

    getSalesorderInfo()
  }, [])

  const cashTransaction = () => {
    setAmount(EURO(salesorder.TOTAL).value)
    setCashPopup(true)
  }

  const pinTransaction = () => {
    setAmount(EURO(salesorder.TOTAL).value)
    setPinPopup(true)
  }

  const startCashTransaction = () => {
    setCashPopup(false)
    startTransaction({
      type: 'CASH',
      insphireType: 'SALESORDER',
      insphireNumber: salesorder.CONTNO,
    })
  }

  const startPinTransaction = () => {
    setPinPopup(false)
    startTransaction({
      type: 'PIN',
      insphireType: 'SALESORDER',
      insphireNumber: salesorder.CONTNO,
    })
  }

  if (loading)
    return (
      <PageLayout>
        <div className="w-full h-full grid place-items-center">
          <Spinner />
        </div>
      </PageLayout>
    )

  return (
    <PageLayout>
      <div className="w-full h-full grid grid-cols-2 gap-10">
        <SalesorderInfo salesorder={salesorder} />
        <SalesorderItems items={items} />
        <SalesorderTransactions transactions={transactions} />
        <SalesorderAmount
          salesorder={salesorder}
          pinTransaction={pinTransaction}
          cashTransaction={cashTransaction}
        />
      </div>
      {cashPopup && (
        <CashAmountPopup
          setShow={setCashPopup}
          startTransaction={startCashTransaction}
          chooseType={false}
        />
      )}
      {pinPopup && (
        <PinAmountPopup
          setShow={setPinPopup}
          startTransaction={startPinTransaction}
          chooseType={false}
        />
      )}
    </PageLayout>
  )
}

export default SalesOrderSingle
