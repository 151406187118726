import useTransaction from '../../../contexts/TransactionContext'
import AmountInput from '../../atoms/AmountInput'

const CashAmountPopup = ({ setShow, startTransaction, chooseType = true }) => {
  const {
    amount,
    setAmount,
    insphireTransactionType,
    setInsphireTransactionType,
  } = useTransaction()

  return (
    <div className="fixed inset-0 z-40 bg-black bg-opacity-25 grid place-items-center">
      <div className="bg-gray-100 rounded-lg p-10 flex flex-col gap-5">
        <div className="flex justify-center">
          <h2 className="text-xl uppercase font-semibold">CASH</h2>
        </div>
        <div className="flex flex-col items-center gap-10">
          <div className="flex flex-col items-center gap-2">
            <span className="uppercase">Bedrag</span>
            <AmountInput amount={amount} setAmount={setAmount} />
          </div>
          {chooseType && (
            <div className="flex flex-wrap justify-center items-center gap-3">
              <span className="w-full uppercase text-center">
                Insphire transactie type
              </span>
              <button
                onClick={() => setInsphireTransactionType('DEPOSIT')}
                className={`w-40 py-2 font-semibold ${
                  insphireTransactionType === 'DEPOSIT'
                    ? 'bg-rixax-primary'
                    : 'bg-gray-200'
                }`}
              >
                BORG
              </button>
              <button
                onClick={() => setInsphireTransactionType('PAYMENT')}
                className={`w-40 py-2 font-semibold ${
                  insphireTransactionType === 'PAYMENT'
                    ? 'bg-rixax-primary'
                    : 'bg-gray-200'
                }`}
              >
                BETALING
              </button>
            </div>
          )}
          <div className="flex flex-col items-center gap-2">
            <button
              onClick={startTransaction}
              className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-green-500 hover:bg-green-600"
            >
              Afrekenen
            </button>
            <button
              onClick={() => setShow(false)}
              className="w-96 h-20 flex justify-center items-center text-center text-white uppercase text-lg font-semibold bg-red-600 hover:bg-red-700"
            >
              Annuleren
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CashAmountPopup
