import useSession from '../../contexts/SessionContext'

const UserDisplay = () => {
  const { user, logout } = useSession()

  return (
    <div className="flex items-center gap-5">
      <div>
        <span className="text-lg text-white font-semibold">{`${user.firstname} ${user.lastname}`}</span>
      </div>
      <button
        onClick={logout}
        className="w-8 h-8 rounded-lg grid place-items-center bg-white text-neutral-900"
      >
        <i className="fa-solid fa-right-from-bracket" />
      </button>
    </div>
  )
}

export default UserDisplay
