import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../api'
import Input from '../atoms/Input'
import Spinner from '../atoms/Spinner'

const SalesorderSearch = () => {
  const [searchterm, setSearchterm] = useState('')
  const [results, setResults] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getSalesorders = async () => {
      setLoading(true)
      const data = await api({
        endpoint: `/salesorders/search?searchterm=${searchterm}`,
      })

      setResults(data.salesorders)
      setLoading(false)
    }

    let timeout
    if (searchterm && searchterm.length > 2) {
      timeout = setTimeout(() => {
        getSalesorders()
      }, 750)
    } else {
      setResults([])
    }

    return () => clearTimeout(timeout)
  }, [searchterm])

  return (
    <div className="relative w-fit flex items-end gap-4 pb-2">
      <Input
        name="search"
        label=""
        type="search"
        value={searchterm}
        setValue={setSearchterm}
      />
      {loading && (
        <div className="mb-2">
          <Spinner />
        </div>
      )}
      {results.length > 0 && (
        <div className="absolute top-full left-0 z-10 bg-white shadow-md border border-neutral-900 flex flex-col divide-y divide-neutral-400 max-h-60 overflow-y-auto overflow-x-hidden">
          {results.map((cont) => (
            <Link
              to={`/salesorders/${cont.CONTNO}`}
              key={cont.RECID}
              className="relative w-[35rem] h-20 p-3 flex flex-col items-start justify-between hover:bg-gray-100"
            >
              <span className="font-semibold text-lg">{cont.CONTNO}</span>
              <span>
                {cont.ACCTNAME} ({cont.ACCT})
              </span>
              <span className="absolute top-2 right-2 uppercase text-xs text-neutral-700">
                {cont.ACCT === '000001' ? 'Particulier' : 'Zakelijk'}
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default SalesorderSearch
