const SalesorderInfo = ({ salesorder }) => {
  return (
    <div className="flex flex-col gap-2">
      <h2 className="uppercase">Salesorder</h2>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Salesordernummer</span>
          <span className="">{salesorder.CONTNO}</span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Soort klant</span>
          <span className="">
            {salesorder.ACCT === '000001' ? 'Particulier' : 'Zakelijk'}
          </span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Klant</span>
          <span className="">{salesorder.ACCTNAME}</span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Klantnummer</span>
          <span className="">{salesorder.ACCT}</span>
        </div>
        {salesorder.ACCT === '000001' && (
          <div className="flex items-center gap-2 border-b justify-between">
            <span className="text-sm font-semibold">Contant klantnummer</span>
            <span className="">{salesorder.CCREC}</span>
          </div>
        )}
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Datum</span>
          <span className="">
            {new Date(salesorder.HIREDATE).toLocaleDateString()}{' '}
            {salesorder.HIRETIME}
          </span>
        </div>
        <div className="flex flex-col gap-2 border-b mt-3">
          <span className="text-sm font-semibold">Klant referentie</span>
          <span>{salesorder.THEIRREF}</span>
        </div>
        <div className="flex flex-col gap-2 border-b mt-3">
          <span className="text-sm font-semibold">Memo</span>
          <span>{salesorder.MEMO}</span>
        </div>
      </div>
    </div>
  )
}

export default SalesorderInfo
