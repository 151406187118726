import LastSalesorders from '../components/organisms/LastSalesorders'
import SalesorderSearch from '../components/organisms/SalesorderSeach'
import PageLayout from '../layouts/PageLayout'

const Salesorders = () => {
  return (
    <PageLayout>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="uppercase">Salesorder zoeken</h2>
          <SalesorderSearch />
        </div>
        <LastSalesorders />
      </div>
    </PageLayout>
  )
}

export default Salesorders
