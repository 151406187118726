import useSession from '../../../contexts/SessionContext'
import { Navigate } from 'react-router-dom'

const ProtectedRoute = ({ children }) => {
  const { loggedIn } = useSession()

  if (!loggedIn) return <Navigate to="/login" replace />

  return <>{children}</>
}

export default ProtectedRoute
