import { useId } from 'react'

const Input = ({
  name = 'input',
  label = 'Input',
  type = 'text',
  value,
  setValue,
  error = '',
}) => {
  const id = useId()

  return (
    <div className="w-full flex flex-col gap-0.5">
      <label htmlFor={id} className="text-sm font-semibold">
        {label}
      </label>
      <input
        className={`w-80 px-3 py-2 rounded-lg focus:outline-none focus:border-black focus:ring-black ${
          error ? 'border-red-600' : 'border-black'
        }`}
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
      {error && (
        <div className="flex items-center gap-1.5 mt-1 text-red-600">
          <span className="text-sm">{error}</span>
        </div>
      )}
    </div>
  )
}

export default Input
