import { useState } from 'react'
import EURO from '../../utils/euro'

const AmountInput = ({ amount, setAmount, size = 'text-3xl' }) => {
  const [string, setString] = useState(EURO(amount).format())

  const format = () => {
    const x = string.replaceAll(/_/g, '').replaceAll(',', '.')

    const number = parseFloat(x) || amount

    setAmount(number)
    setString(EURO(number).format())
  }

  return (
    <input
      type="text"
      className={`${size} text-center font-semibold p-3 border-none bg-gray-100 hover:bg-gray-200 focus:ring-0 focus:bg-gray-300`}
      value={string}
      onChange={(e) => setString(e.target.value)}
      onBlur={format}
      onFocusCapture={() => setString('')}
    />
  )
}

export default AmountInput
