import { Link, useLocation } from 'react-router-dom'
import useSession from '../../contexts/SessionContext'

const Menu = () => {
  const { pathname } = useLocation()
  const { user } = useSession()

  return (
    <nav>
      <ul className="flex items-center">
        <Link to="/">
          <li
            className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
              pathname === '/'
                ? 'bg-rixax-primary text-neutral-800'
                : 'text-white hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <i className="fa-solid fa-chart-line text-xl" />
            <span className="font-semibold text-xs">Dashboard</span>
          </li>
        </Link>
        <Link to="/huurcontracten">
          <li
            className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
              pathname.includes('huurcontract')
                ? 'bg-rixax-primary text-neutral-800'
                : 'text-white hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <i className="fa-solid fa-file-contract text-xl" />
            <span className="font-semibold text-xs">Huurcontracten</span>
          </li>
        </Link>
        <Link to="/salesorders">
          <li
            className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
              pathname.includes('salesorder')
                ? 'bg-rixax-primary text-neutral-800'
                : 'text-white hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <i className="fa-solid fa-shopping-cart text-xl" />
            <span className="font-semibold text-xs">Salesorders</span>
          </li>
        </Link>
        <Link to="/transacties">
          <li
            className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
              pathname.includes('transacties')
                ? 'bg-rixax-primary text-neutral-800'
                : 'text-white hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <i className="fa-solid fa-receipt text-xl" />
            <span className="font-semibold text-xs">Transacties</span>
          </li>
        </Link>
        <Link to="/kassa">
          <li
            className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
              pathname.includes('kassa')
                ? 'bg-rixax-primary text-neutral-800'
                : 'text-white hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <i className="fa-solid fa-cash-register text-xl" />
            <span className="font-semibold text-xs">Kassa</span>
          </li>
        </Link>
        {user && user.role === 'admin' && (
          <Link to="/gebruikers">
            <li
              className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
                pathname.includes('gebruikers')
                  ? 'bg-rixax-primary text-neutral-800'
                  : 'text-white hover:bg-white hover:bg-opacity-10'
              }`}
            >
              <i className="fa-solid fa-users text-xl" />
              <span className="font-semibold text-xs">Gebruikers</span>
            </li>
          </Link>
        )}
        <Link to="/instellingen">
          <li
            className={`h-16 w-32 flex flex-col items-center justify-center gap-0.5 transition-colors ${
              pathname.includes('instellingen')
                ? 'bg-rixax-primary text-neutral-800'
                : 'text-white hover:bg-white hover:bg-opacity-10'
            }`}
          >
            <i className="fa-solid fa-gear text-xl" />
            <span className="font-semibold text-xs">Instellingen</span>
          </li>
        </Link>
      </ul>
    </nav>
  )
}

export default Menu
