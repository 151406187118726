import { useEffect, useId, useState } from 'react'
import { Link } from 'react-router-dom'
import api from '../../api'
import Button from '../atoms/Button'
import Spinner from '../atoms/Spinner'

const LastSalesorders = () => {
  const zid = useId()
  const pid = useId()

  const [salesorders, setSalesorders] = useState([])
  const [loading, setLoading] = useState(true)

  const [particulier, setParticulier] = useState(
    localStorage.getItem('particulier') === '1' ? true : false
  )
  const [zakelijk, setZakelijk] = useState(
    localStorage.getItem('zakelijk') === '1' ? true : false
  )

  const getSalesorders = async () => {
    setLoading(true)
    const data = await api({
      endpoint: `/salesorders/last?limit=8&particulier=${
        particulier ? 1 : 0
      }&zakelijk=${zakelijk ? 1 : 0}`,
    })
    setSalesorders(data.salesorders)
    setLoading(false)
  }

  useEffect(() => {
    getSalesorders()

    localStorage.setItem('particulier', particulier ? '1' : '0')
    localStorage.setItem('zakelijk', zakelijk ? '1' : '0')
  }, [particulier, zakelijk])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center h-10 justify-between">
        <h2 className="uppercase">Laatst geopende salesorders</h2>
        <div className="mr-auto flex items-center gap-5 px-10">
          <div className="flex items-center gap-2">
            <input
              id={pid}
              type="checkbox"
              className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
              checked={particulier}
              onChange={(e) => setParticulier(e.target.checked)}
            />
            <label className="cursor-pointer " htmlFor={pid}>
              Particulier
            </label>
          </div>
          <div className="flex items-center gap-2">
            <input
              id={zid}
              type="checkbox"
              className="cursor-pointer appearance-none checked:bg-neutral-900 hover:checked:bg-neutral-900 focus:ring-0 focus:checked:bg-neutral-900"
              checked={zakelijk}
              onChange={(e) => setZakelijk(e.target.checked)}
            />
            <label className="cursor-pointer " htmlFor={zid}>
              Zakelijk
            </label>
          </div>
        </div>
        {loading ? (
          <Spinner />
        ) : (
          <Button onClick={getSalesorders}>Verversen</Button>
        )}
      </div>
      {salesorders.length > 0 && (
        <div className="relative max-h-[70vh] overflow-y-auto flex flex-col gap-1 border border-neutral-900 divide-y divide-neutral-400">
          {salesorders.map((cont) => (
            <Link
              key={cont.RECID}
              to={`/salesorders/${cont.CONTNO}`}
              className="relative w-full h-20 hover:bg-gray-100 p-3 flex flex-col items-start justify-between"
            >
              <span className="font-semibold text-lg">{cont.CONTNO}</span>
              <span>
                {cont.ACCTNAME} ({cont.ACCT})
              </span>
              <span className="absolute top-2 right-2 uppercase text-xs text-neutral-700">
                {cont.ACCT === '000001' ? 'Particulier' : 'Zakelijk'}
              </span>
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default LastSalesorders
