import { createContext, useContext, useEffect, useState } from 'react'
import api from '../api'
import Spinner from '../components/atoms/Spinner'
import DayEndedPopup from '../components/organisms/DayEndedPopup'
import StartDayPopup from '../components/organisms/StartDayPopup'
import useSession from './SessionContext'

const CashstateContext = createContext()

export const CashstateProvider = ({ children }) => {
  const { loggedIn, logout } = useSession()

  const [loading, setLoading] = useState(false)

  const [startCashstate, setStartCashstate] = useState(false)
  const [dayEnded, setDayEnded] = useState(false)
  const [cashstate, setCashstate] = useState(null)

  const [startAmount, setStartAmount] = useState(0)

  useEffect(() => {
    const getCashstate = async () => {
      setLoading(true)
      const data = await api({ endpoint: '/cashstates/current' })

      console.log(data)

      if (data.error) {
        setLoading(false)
        return setStartCashstate(true)
      }

      if (data.cashstate.status !== 'in-progress') {
        setLoading(false)
        setDayEnded(true)
        return setTimeout(() => {
          setDayEnded(false)
          logout()
        }, 3000)
      }

      setCashstate(data.cashstate)
      setLoading(false)
    }

    if (loggedIn) {
      getCashstate()
    }
  }, [loggedIn])

  const startDay = async () => {
    const data = await api({
      endpoint: '/cashstates/start',
      method: 'POST',
      body: {
        start_amount: startAmount,
      },
    })

    setCashstate(data.cashstate)
    setStartCashstate(false)
  }

  const values = {
    cashstate,
    setCashstate,
    startCashstate,
    setStartCashstate,
    startAmount,
    setStartAmount,
    startDay,
  }

  if (loading)
    return (
      <div className="w-full h-screen grid place-items-center">
        <Spinner />
      </div>
    )

  return (
    <CashstateContext.Provider value={values}>
      <>{children}</>
      {startCashstate && <StartDayPopup />}
      {dayEnded && <DayEndedPopup />}
    </CashstateContext.Provider>
  )
}

const useCashstate = () => useContext(CashstateContext)

export default useCashstate
