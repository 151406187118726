import { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../api'
import Receipt from '../components/organisms/Receipt'
import Transaction from '../components/organisms/Transaction'
import EURO from '../utils/euro'
import useGeneral from './GeneralContext'

const TransactionContext = createContext()

export const TransactionProvider = ({ children }) => {
  const navigate = useNavigate()
  const { terminal, terminals } = useGeneral()

  const [show, setShow] = useState(false)

  const [status, setStatus] = useState('pending')
  const [statusText, setStatusText] = useState('')
  const [type, setType] = useState('PIN')
  const [amount, setAmount] = useState(0)
  const [insphireNumber, setInsphireNumber] = useState('')
  const [insphireType, setInsphireType] = useState('')
  const [insphireTransactionType, setInsphireTransactionType] =
    useState('DEPOSIT')
  const [transactionID, setTransactionID] = useState('')

  const [PINtransactionID, setPINtransactionID] = useState('')
  const [PINpaymentReference, setPINpaymentReference] = useState('')
  const [PINreceipt, setPINreceipt] = useState('')
  const [PINterminalName, setPINterminalName] = useState(
    terminals.find((t) => t.id === terminal)?.name || ''
  )
  const [PINstatusURL, setPINstatusURL] = useState('')
  const [PINcancelURL, setPINcancelURL] = useState('')

  const [CASHamountPayed, setCASHamountPayed] = useState(0)
  const [CASHamountChange, setCASHamountChange] = useState(0)

  const [receipt, setReceipt] = useState('')

  useEffect(
    () =>
      setPINterminalName(terminals.find((t) => t.id === terminal)?.name || ''),
    [terminal, terminals]
  )

  useEffect(
    () => setCASHamountChange(EURO(CASHamountPayed).subtract(amount).value),
    [CASHamountPayed, amount]
  )

  const checkPINtransaction = async (url, id) => {
    const res = await fetch(url)
    const data = await res.json()

    if (data.status !== 'final')
      return setTimeout(() => checkPINtransaction(url, id), 1000)

    if (data.error === '1' && data.cancelled === '0') {
      setStatus('error')
      setStatusText(data.incidentcodetext)

      await api({
        endpoint: `/transactions/complete/${id}`,
        method: 'PUT',
        body: {},
      })

      return setTimeout(() => {
        setTransactionID('')
        setAmount(0)
        setShow(false)
        setPINtransactionID('')
        setPINpaymentReference('')
        setPINstatusURL('')
        setPINcancelURL('')
      }, 3000)
    }

    if (data.cancelled === '1') {
      setStatus('cancelled')
      setStatusText(data.incidentcodetext)

      await api({
        endpoint: `/transactions/complete/${id}`,
        method: 'PUT',
        body: {},
      })

      return setTimeout(() => {
        setTransactionID('')
        setAmount(0)
        setShow(false)
        setPINtransactionID('')
        setPINpaymentReference('')
        setPINstatusURL('')
        setPINcancelURL('')
      }, 3000)
    }

    if (data.approved === '1') {
      setStatus('completed')
      setStatusText(data.incidentcodetext)

      const transactionData = await api({
        endpoint: `/transactions/complete/${id}`,
        method: 'PUT',
        body: {},
      })

      console.log(transactionData)

      return setTimeout(() => {
        setTransactionID('')
        setAmount(0)
        setShow(false)
        setPINtransactionID('')
        setPINpaymentReference('')
        setPINstatusURL('')
        setPINcancelURL('')
        navigate('/huurcontracten')
        setReceipt(transactionData.transaction.receipt)
      }, 3000)
    }
  }

  const startTransaction = async ({ type, insphireType, insphireNumber }) => {
    const body = {
      transaction_type: type,
      amount,
      insphire_type: insphireType,
      insphire_number: insphireNumber,
      insphire_transaction_type:
        insphireType === 'SALESORDER' ? 'PAYMENT' : insphireTransactionType,
      ...(type === 'PIN' && { pin_terminal: terminal }),
      ...(type === 'PIN' && { pin_terminal_name: PINterminalName }),
    }

    const data = await api({
      endpoint: '/transactions/start',
      method: 'POST',
      body,
    })

    console.log(data)

    setTransactionID(data.transaction._id)
    setStatus('pending')
    setStatusText('PENDING')
    setType(type)
    setInsphireType(insphireType)
    setInsphireNumber(insphireNumber)
    setShow(true)

    if (type === 'PIN') {
      setPINtransactionID(data.transaction.pin_transaction_id)
      setPINpaymentReference(data.transaction.pin_payment_reference)
      setPINstatusURL(data.transaction.pin_status_url)
      setPINcancelURL(data.transaction.pin_cancel_url)
      await checkPINtransaction(
        data.transaction.pin_status_url,
        data.transaction._id
      )
    }
  }

  const cancelTransaction = async () => {
    if (type === 'PIN') {
      return await fetch(PINcancelURL)
    }

    if (type === 'CASH') {
      await api({
        endpoint: `/transactions/complete/${transactionID}`,
        method: 'PUT',
        body: {
          cancel: true,
        },
      })

      setStatus('cancelled')
      setStatusText('CANCELLED')

      return setTimeout(() => {
        setTransactionID('')
        setAmount(0)
        setCASHamountPayed(0)
        setCASHamountChange(0)
        setShow(false)
      }, 3000)
    }
  }

  const completeTransaction = async () => {
    if (type === 'CASH') {
      const data = await api({
        endpoint: `/transactions/complete/${transactionID}`,
        method: 'PUT',
        body: {
          cash_amount_payed: CASHamountPayed,
          cash_amount_change: CASHamountChange,
        },
      })

      console.log(data)

      setStatus('completed')
      setStatusText('SUCCESS')

      return setTimeout(() => {
        setTransactionID('')
        setAmount(0)
        setCASHamountPayed(0)
        setCASHamountChange(0)
        setShow(false)
        navigate('/huurcontracten')
        setReceipt(data.transaction.receipt)
      }, 3000)
    }
  }

  const values = {
    show,
    setShow,
    status,
    setStatus,
    statusText,
    setStatusText,
    type,
    setType,
    amount,
    setAmount,
    insphireNumber,
    setInsphireNumber,
    insphireType,
    setInsphireType,
    insphireTransactionType,
    setInsphireTransactionType,
    PINtransactionID,
    setPINtransactionID,
    PINpaymentReference,
    setPINpaymentReference,
    PINreceipt,
    setPINreceipt,
    PINterminalName,
    setPINterminalName,
    PINstatusURL,
    setPINstatusURL,
    PINcancelURL,
    setPINcancelURL,
    CASHamountPayed,
    setCASHamountPayed,
    CASHamountChange,
    setCASHamountChange,
    startTransaction,
    cancelTransaction,
    completeTransaction,
  }

  return (
    <TransactionContext.Provider value={values}>
      <>{children}</>
      {show && <Transaction />}
      {receipt && (
        <Receipt
          receipt={receipt}
          setReceipt={setReceipt}
          insphireNumber={insphireNumber}
          insphireType={insphireType}
        />
      )}
    </TransactionContext.Provider>
  )
}

const useTransaction = () => useContext(TransactionContext)

export default useTransaction
