import { useEffect, useState } from 'react'
import api from '../../api'
import Spinner from '../atoms/Spinner'

const TestmodeSelect = () => {
  const [loading, setLoading] = useState(true)
  const [testmode, setTestmode] = useState(false)
  const [url, setUrl] = useState('')

  useEffect(() => {
    const getTestmode = async () => {
      try {
        setLoading(true)

        const data = await api({
          endpoint: '/testmode',
        })
        setTestmode(data.testmode)
        setUrl(data.base_url)

        setLoading(false)
      } catch (err) {
        setLoading(false)
      }
    }

    getTestmode()
  }, [])

  const setTestmodeInsphire = async (flag) => {
    try {
      setLoading(true)

      await api({
        endpoint: '/testmode',
        method: 'POST',
        body: { testmode: flag },
      })

      setTimeout(async () => {
        const data = await api({
          endpoint: '/testmode',
        })
        setTestmode(data.testmode)
        setUrl(data.base_url)
        setLoading(false)
      }, 1000)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col gap-1">
      <h2 className="text-lg uppercase">TESTMODES (INSPHIRE)</h2>
      {loading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <div>
            <div
              onClick={() => setTestmodeInsphire(!testmode)}
              className={`cursor-pointer relative w-12 h-6 rounded-full ${
                testmode ? 'bg-green-400' : 'bg-gray-200'
              }`}
            >
              <div
                className={`absolute top-1/2 -translate-y-1/2 w-5 h-5 rounded-full ${
                  testmode ? 'bg-green-600 right-1' : 'bg-gray-400 left-1'
                }`}
              />
            </div>
          </div>
          <p className="text-sm text-neutral-500">{url}</p>
        </>
      )}
    </div>
  )
}

export default TestmodeSelect
