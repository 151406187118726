import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from './components/templates/routing/ProtectedRoute'

// Pages
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import Huurcontracten from './pages/Huurcontracten'
import Instellingen from './pages/Instellingen'
import Kassa from './pages/Kassa'
import Salesorders from './pages/Salesorders'
import HuurcontractSingle from './pages/HuurcontractSingle'
import Transacties from './pages/Transacties'
import SalesOrderSingle from './pages/SalesorderSingle'
import Gebruikers from './pages/Gebruikers'

const Routing = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />

      <Route
        index
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />

      <Route
        path="/huurcontracten"
        element={
          <ProtectedRoute>
            <Huurcontracten />
          </ProtectedRoute>
        }
      />

      <Route
        path="/huurcontracten/:contractnumber"
        element={
          <ProtectedRoute>
            <HuurcontractSingle />
          </ProtectedRoute>
        }
      />

      <Route
        path="/salesorders"
        element={
          <ProtectedRoute>
            <Salesorders />
          </ProtectedRoute>
        }
      />

      <Route
        path="/salesorders/:salesordernumber"
        element={
          <ProtectedRoute>
            <SalesOrderSingle />
          </ProtectedRoute>
        }
      />

      <Route
        path="/transacties"
        element={
          <ProtectedRoute>
            <Transacties />
          </ProtectedRoute>
        }
      />

      <Route
        path="/kassa"
        element={
          <ProtectedRoute>
            <Kassa />
          </ProtectedRoute>
        }
      />

      <Route
        path="/gebruikers"
        element={
          <ProtectedRoute>
            <Gebruikers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/instellingen"
        element={
          <ProtectedRoute>
            <Instellingen />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}

export default Routing
