import EURO from '../../../utils/euro'

const ContractAmount = ({
  contract,
  prices,
  cashTransaction,
  pinTransaction,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="uppercase">Bedrag</h2>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Goederen</span>
          <span className="">
            {EURO(prices.GOODS).subtract(prices.INSVAL).format()}
          </span>
        </div>
        <div className="flex items-center gap-2 border-b justify-between">
          <span className="text-sm font-semibold">Verzekering</span>
          <span className="">{EURO(prices.INSVAL).format()}</span>
        </div>
        <div className="flex items-center gap-2  justify-between">
          <span className="text-sm font-semibold">Belasting</span>
          <span className="">{EURO(prices.VAT).format()}</span>
        </div>
        <div className="w-full h-px bg-neutral-500 mt-3" />
        <div className="flex items-center gap-2 border-b justify-between mt-3">
          <span className="text-sm font-semibold">Totaal huurbedrag</span>
          <span className="">{EURO(prices.TOTAL).format()}</span>
        </div>
        <div className="flex items-center gap-2 justify-between">
          <span className="text-sm font-semibold">Borg</span>
          <span className="">
            {EURO(contract.ACCT === '000001' ? prices.DEPOSIT : 0).format()}
          </span>
        </div>
        <div className="w-full h-px bg-neutral-500 mt-3" />
        <div className="flex items-center gap-2  justify-between mt-3">
          <span className="text-sm font-semibold">Totaal bedrag</span>
          <span className="">
            {EURO(prices.TOTAL)
              .add(contract.ACCT === '000001' ? prices.DEPOSIT : 0)
              .format()}
          </span>
        </div>
      </div>
      <div className="w-full flex gap-5 mt-auto">
        <button
          onClick={cashTransaction}
          className="relative w-full h-20 bg-yellow-600 text-white uppercase font-semibold hover:bg-yellow-700"
        >
          <i className="absolute left-8 top-1/2 -translate-y-1/2 fa-solid fa-coins text-2xl" />
          <span className="text-2xl">Cash</span>
        </button>
        <button
          onClick={pinTransaction}
          className="relative w-full h-20 bg-green-500 text-white uppercase font-semibold hover:bg-green-600"
        >
          <i className="absolute left-8 top-1/2 -translate-y-1/2 fa-solid fa-credit-card text-2xl" />
          <span className="text-2xl">Pin</span>
        </button>
      </div>
    </div>
  )
}

export default ContractAmount
