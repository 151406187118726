import ContractSearch from '../components/organisms/ContractSearch'
import LastContracts from '../components/organisms/LastContracts'
import PageLayout from '../layouts/PageLayout'

const Huurcontracten = () => {
  return (
    <PageLayout>
      <div className="flex flex-col gap-5">
        <div className="flex flex-col gap-1">
          <h2 className="uppercase">Contract zoeken</h2>
          <ContractSearch />
        </div>
        <LastContracts />
      </div>
    </PageLayout>
  )
}

export default Huurcontracten
