import { Link } from 'react-router-dom'
import Logo from '../../assets/Logo'
import UserDisplay from '../molecules/UserDisplay'
import Menu from './Menu'

const Header = () => {
  return (
    <header className="w-full h-16 bg-neutral-900 px-10 flex items-center">
      <div>
        <Link to="/">
          <Logo className="w-20" color="fill-white" />
        </Link>
      </div>
      <div className="ml-auto flex items-center gap-10">
        <Menu />
        <UserDisplay />
      </div>
    </header>
  )
}

export default Header
