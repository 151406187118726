import EURO from '../../../utils/euro'

const Totalen = ({ stats }) => {
  return (
    <div className="w-full h-fit flex flex-col gap-2 border p-2 pb-10">
      <h3 className="uppercase text-xl">Totalen</h3>
      <div className="w-full h-full flex flex-col gap-1 p-1">
        <div className="w-full">
          <span className="font-semibold uppercase text-sm">Vandaag</span>
        </div>
        <div className="ml-2 flex items-center justify-between border-b">
          <span className="font-semibold text-sm">Totaal aantal</span>
          <span className="text-end">
            {stats.total_completed_transactions_today}
          </span>
        </div>
        <div className="ml-2 flex items-center justify-between border-b">
          <span className="font-semibold text-sm">Totaal bedrag</span>
          <span className="text-end">
            {EURO(stats.total_amount_today).format()}
          </span>
        </div>
        <div className="w-full mt-3">
          <span className="font-semibold uppercase text-sm">Week</span>
        </div>
        <div className="ml-2 flex items-center justify-between border-b">
          <span className="font-semibold text-sm">Totaal aantal</span>
          <span className="text-end">
            {stats.total_completed_transactions_week}
          </span>
        </div>
        <div className="ml-2 flex items-center justify-between border-b">
          <span className="font-semibold text-sm">Totaal bedrag</span>
          <span className="text-end">
            {EURO(stats.total_amount_week).format()}
          </span>
        </div>
      </div>
    </div>
  )
}

export default Totalen
