import { createContext, useContext, useEffect, useState } from 'react'
import api from '../api'
import useSession from './SessionContext'

const GeneralContext = createContext()

export const GeneralProvider = ({ children }) => {
  const { loggedIn } = useSession()

  const [terminals, setTerminals] = useState([])
  const [terminal, setTerminal] = useState(localStorage.getItem('terminal'))

  useEffect(() => {
    const getTerminals = async () => {
      const data = await api({
        endpoint: '/terminals',
      })

      setTerminals(data.terminals)
    }

    if (loggedIn) {
      getTerminals()
    }
  }, [loggedIn])

  useEffect(() => localStorage.setItem('terminal', terminal), [terminal])

  const values = {
    terminal,
    setTerminal,
    terminals,
    setTerminals,
  }

  return (
    <GeneralContext.Provider value={values}>
      <>{children}</>
    </GeneralContext.Provider>
  )
}

const useGeneral = () => useContext(GeneralContext)

export default useGeneral
