import { useState } from 'react'
import api from '../../../api'
import Button from '../../atoms/Button'
import Input from '../../atoms/Input'
import Spinner from '../../atoms/Spinner'

const GebruikerToevoegenPopup = ({ setShow }) => {
  const [loading, setLoading] = useState(false)

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')

  const createUser = async () => {
    try {
      setLoading(true)

      await api({
        endpoint: '/users',
        method: 'POST',
        body: {
          username,
          password,
          role: 'employee',
          firstname,
          lastname,
        },
      })

      setShow(false)
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-25 grid place-items-center">
      <div className="relative bg-gray-100 rounded-lg p-10 flex flex-col gap-10">
        {loading ? (
          <Spinner />
        ) : (
          <>
            <button
              onClick={() => setShow(false)}
              className="absolute top-2 right-3"
            >
              <i className="fa-solid fa-xmark text-red-600 text-lg" />
            </button>
            <Input
              name="username"
              label="Gebruikersnaam"
              value={username}
              setValue={setUsername}
            />
            <Input
              name="password"
              label="Wachtwoord"
              type="password"
              value={password}
              setValue={setPassword}
            />
            <Input
              name="firstname"
              label="Voornaam"
              value={firstname}
              setValue={setFirstname}
            />
            <Input
              name="lastname"
              label="Achternaam"
              value={lastname}
              setValue={setLastname}
            />
            <Button onClick={createUser}>Aanmaken</Button>
          </>
        )}
      </div>
    </div>
  )
}

export default GebruikerToevoegenPopup
