import { useEffect, useState } from 'react'
import api from '../../../api'
import EURO from '../../../utils/euro'
import Spinner from '../../atoms/Spinner'

const KassaPayments = () => {
  const [loading, setLoading] = useState(true)

  const [pinAmount, setPinAmount] = useState(0)
  const [cashAmount, setCashAmount] = useState(0)
  const [totalAmount, setTotalAmount] = useState(0)

  useEffect(() => {
    const getData = async () => {
      setLoading(true)

      const data = await api({ endpoint: '/dashboard' })

      setPinAmount(data.total_amount_pin_today)
      setCashAmount(data.total_amount_cash_today)
      setTotalAmount(data.total_amount_today)

      setLoading(false)
    }

    getData()
  }, [])

  if (loading)
    return (
      <div className="w-full grid place-items-center border">
        <Spinner />
      </div>
    )

  return (
    <div className="w-full flex flex-col gap-2 p-5 border">
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Totaal CASH</span>
        <span className="text-lg">{EURO(cashAmount).format()}</span>
      </div>
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Totaal PIN</span>
        <span className="text-lg">{EURO(pinAmount).format()}</span>
      </div>
      <div className="w-full h-px bg-gray-500 my-1" />
      <div className="w-full flex justify-between">
        <span className="text-sm font-semibold">Totaal</span>
        <span className="text-lg">{EURO(totalAmount).format()}</span>
      </div>
    </div>
  )
}

export default KassaPayments
