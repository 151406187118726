import { useEffect, useState } from 'react'
import api from '../../../api'
import EURO from '../../../utils/euro'
import AmountInput from '../../atoms/AmountInput'
import Spinner from '../../atoms/Spinner'

const Uitgaven = () => {
  const [loading, setLoading] = useState(true)

  const [expenses, setExpenses] = useState([])
  const [by, setBy] = useState('')
  const [description, setDescription] = useState('')

  const [amount, setAmount] = useState(0)

  useEffect(() => {
    const getExpenses = async () => {
      setLoading(true)

      const data = await api({ endpoint: '/expenses/today' })

      setExpenses(data.expenses)

      setLoading(false)
    }

    getExpenses()
  }, [])

  const addExpense = async () => {
    if (!amount || !by || !description) return

    setLoading(true)
    const body = {
      amount,
      by,
      description,
    }

    await api({
      endpoint: '/expenses',
      method: 'POST',
      body,
    })

    const data = await api({ endpoint: '/expenses/today' })

    setExpenses(data.expenses)

    setAmount(0)
    setBy('')
    setDescription('')

    setLoading(false)
  }

  const deleteOne = async (id) => {
    await api({
      endpoint: `/expenses/${id}`,
      method: 'DELETE',
    })

    const data = await api({ endpoint: '/expenses/today' })

    setExpenses(data.expenses)
  }

  if (loading)
    return (
      <div className="w-full grid place-items-center border">
        <Spinner />
      </div>
    )

  return (
    <div className="w-full flex flex-col border">
      <div className="w-full flex flex-col gap-2 p-5">
        <div className="w-full">
          <h3 className="uppercase">Kleine uitgaven</h3>
        </div>
        <div className="flex flex-col gap-1">
          {expenses.map((item) => (
            <div
              key={item._id}
              className="w-full px-2 py-1 flex items-center gap-2 bg-gray-100"
            >
              <span className="w-16 flex-shrink-0">
                {new Date(item.date).toLocaleTimeString()}
              </span>
              <span className="w-full truncate">{item.description}</span>
              <span className="w-20 flex-shrink-0">{item.by}</span>
              <span className="w-20 flex-shrink-0 text-end">
                {EURO(item.amount).format()}
              </span>
              <button
                onClick={() => deleteOne(item._id)}
                className="w-12 text-end"
              >
                <i className="fa-solid fa-trash-can text-red-600 text-sm" />
              </button>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex flex-col gap-1 mt-auto bg-gray-200 p-2">
        <div className="flex flex-col">
          <label className="text-sm">Bedrag</label>
          <AmountInput size="text-xl" amount={amount} setAmount={setAmount} />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Door</label>
          <input
            type="text"
            value={by}
            onChange={(e) => setBy(e.target.value)}
            className="bg-gray-100 border-none"
          />
        </div>
        <div className="flex flex-col">
          <label className="text-sm">Omschrijving</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            className="bg-gray-100 border-none"
          />
        </div>
        <div>
          <button
            onClick={addExpense}
            className="w-full py-2 bg-green-500 text-white uppercase mt-2"
          >
            Toevoegen
          </button>
        </div>
      </div>
    </div>
  )
}

export default Uitgaven
