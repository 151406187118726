import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import api from '../api'
import Logo from '../assets/Logo'
import Button from '../components/atoms/Button'
import Input from '../components/atoms/Input'
import Spinner from '../components/atoms/Spinner'
import useSession from '../contexts/SessionContext'

const Login = () => {
  const { setLoggedIn, setUser } = useSession()
  const navigate = useNavigate()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({ username: '', password: '' })

  useEffect(() => setError((prev) => ({ ...prev, username: '' })), [username])
  useEffect(() => setError((prev) => ({ ...prev, password: '' })), [password])

  const signin = async (e) => {
    try {
      e.preventDefault()
      setLoading(true)
      setError({ username: '', password: '' })

      const body = {
        username,
        password,
      }

      const data = await api({
        endpoint: '/login',
        method: 'POST',
        body,
      })

      if (data.error) {
        if (data.error === 'Gebruikersnaam is niet bij ons bekend')
          setError({ username: data.error, password: '' })

        if (data.error === 'Gebruikersnaam en wachtwoord komen niet overeen')
          setError({ username: '', password: data.error })

        return setLoading(false)
      }

      setLoggedIn(data.logged_in)
      setUser(data.user)

      navigate('/')
    } catch (err) {
      setLoading(false)
    }
  }

  return (
    <div className="w-full h-screen grid place-items-center">
      <div className="w-full max-w-md flex flex-col items-center gap-5 p-10 rounded-lg bg-gray-200">
        <div className="w-full flex justify-center mb-5">
          <Logo />
        </div>
        <form onSubmit={signin} className="flex flex-col items-center gap-3">
          <Input
            type="text"
            name="username"
            label="Gebruikersnaam"
            value={username}
            setValue={setUsername}
            error={error.username}
          />
          <Input
            type="password"
            name="password"
            label="Wachtwoord"
            value={password}
            setValue={setPassword}
            error={error.password}
          />
          <div className="w-full mt-5">
            {loading ? <Spinner /> : <Button>Inloggen</Button>}
          </div>
        </form>
      </div>
    </div>
  )
}

export default Login
