import EURO from '../../../utils/euro'

const SalesorderTransactions = ({ transactions }) => {
  return (
    <div className="flex flex-col gap-3">
      <h2 className="uppercase">Transacties</h2>
      <div>
        <div className="w-full px-2 py-1 flex items-center gap-2 text-xs font-semibold">
          <span className="w-40 flex-shrink-0 truncate">Datum</span>
          <span className="w-32 flex-shrink-0 truncate">Methode</span>
          <span className="w-32 flex-shrink-0 truncate">Type</span>
          <span className="truncate ml-auto">Bedrag</span>
        </div>
        <div className="flex flex-col max-h-[20rem] overflow-y-auto">
          {transactions.map((item) => (
            <div
              key={item._id}
              className="w-full px-2 py-1 flex items-center gap-2 border-b"
            >
              <span className="w-40 flex-shrink-0 truncate">
                {new Date(item.created).toLocaleString()}
              </span>
              <span className="w-32 flex-shrink-0 truncate uppercase">
                {item.transaction_type}
              </span>
              <span className="w-32 flex-shrink-0 truncate uppercase">
                {item.insphire_transaction_type}
              </span>
              <span className="truncate ml-auto">
                {EURO(item.amount).format()}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SalesorderTransactions
